import constants from "constants/constants";

export const checkIsCheckoutEnabledListing = (listingData: {
  type: number;
}) => {
  const { scheduleTypesId } = constants;
  return [
    scheduleTypesId.no_schedule,
    scheduleTypesId.webinar,
    scheduleTypesId.content,
    scheduleTypesId.branded_community,
    scheduleTypesId.whatsapp,
    scheduleTypesId.workshop,
  ].includes(listingData.type);
};
