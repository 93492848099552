import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import { appendUrlParams } from "utils/urlUtils";

export const getCheckoutPageShareUrl = (listing) => {
  const { url_slug, uuid, dynamic_link_id = "", is_preview = false } = listing;
  const url = `${getCreatorHostsiteURL().url}/checkout`;

  let actualSlug = uuid;
  if (url_slug) {
    actualSlug = url_slug;
  }

  return appendUrlParams(`${url}/${actualSlug}`, {
    ...(dynamic_link_id && {
      dynamic_link: dynamic_link_id,
    }),
    ...(is_preview && {
      is_preview: "true",
    }),
  });
};
