import { checkout_page_button_types } from "../constants/CheckoutPages.constants";
import {
  checkout_page_reducer_keys,
  CHECKOUT_PAGES_ACTION_TYPES,
} from "../constants/CheckoutPagesActions.constants";
import {
  ICheckoutPageBasicDetails,
  ICheckoutPageFormDetails,
  ICheckoutPageFormMetaDetails,
} from "../modules/CheckoutPageSections/interfaces/CheckoutPageSections.interfaces";
import { default_checkout_page_color_palette } from "../modules/CheckoutPageSections/modules/ColorPaletteSection/ColorPaletteSection.constants";
import { default_checkout_page_selected_tab } from "../modules/EditCheckoutPanel/EditCheckoutPanel.constants";

export const checkout_page_reducer_initial_state: {
  basicDetails: ICheckoutPageBasicDetails;
  formDetails: ICheckoutPageFormDetails;
  formMetaDetails: ICheckoutPageFormMetaDetails;
} = {
  [checkout_page_reducer_keys.basic_details]: {
    listingUuid: "",
    dynamicLinkUuid: "",
    checkoutPageSelectedTab: default_checkout_page_selected_tab.value,
    openSection: 1,
  },
  [checkout_page_reducer_keys.form_details]: {
    [checkout_page_reducer_keys.listing_details]: {
      listingTitle: "",
      creatorImage: "",
      creatorName: "",
    },
    [checkout_page_reducer_keys.description_section]: {
      description: "",
    },
    [checkout_page_reducer_keys.cover_section]: {
      coverImage: "",
    },
    [checkout_page_reducer_keys.button_text_section]: {
      buttonType: checkout_page_button_types.default,
      ctaData: {
        mobileButtonText: "",
        paymentButtonText: "",
      },
    },
    [checkout_page_reducer_keys.color_palette]:
      default_checkout_page_color_palette,
    [checkout_page_reducer_keys.default_color_palette]:
      default_checkout_page_color_palette,
    [checkout_page_reducer_keys.tab_assets]: {
      tabTitle: "",
      tabIcon: "",
    },
    [checkout_page_reducer_keys.order_bumps_heading_section]: {
      heading: "Bought Together",
    },
    [checkout_page_reducer_keys.order_bumps_details]: {
      orderBumpsCount: 0,
      orderBumpsList: [],
    },
  },
  [checkout_page_reducer_keys.form_meta_details]: {
    [checkout_page_reducer_keys.listing_details]: {
      isEdited: false,
    },
    [checkout_page_reducer_keys.description_section]: {
      isEdited: false,
    },
    [checkout_page_reducer_keys.cover_section]: {
      isEdited: false,
    },
    [checkout_page_reducer_keys.button_text_section]: {
      isEdited: false,
    },
    [checkout_page_reducer_keys.color_palette]: {
      isEdited: false,
    },
    [checkout_page_reducer_keys.tab_assets]: {
      isEdited: false,
    },
    [checkout_page_reducer_keys.order_bumps_heading_section]: {
      isEdited: false,
    },
    [checkout_page_reducer_keys.order_bumps_details]: {
      isEdited: true,
    },
  },
};

export default function checkoutPagesReducer(
  state = checkout_page_reducer_initial_state,
  {
    type,
    payload,
  }: {
    type: string;
    payload: {
      data: {
        [key: string]: string;
      };
    };
  }
) {
  switch (type) {
    case CHECKOUT_PAGES_ACTION_TYPES.SET_CHECKOUT_DATA: {
      const newState = {
        ...state,
        ...payload.data,
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_BASIC_DATA: {
      const currentBasicData = state[checkout_page_reducer_keys.basic_details];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.basic_details]: {
          ...currentBasicData,
          ...payload.data,
        },
      };

      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_FORM_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          ...payload.data,
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_FORM_META_DATA: {
      const currentFormMetaData =
        state[checkout_page_reducer_keys.form_meta_details];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_meta_details]: {
          ...currentFormMetaData,
          ...payload.data,
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_LISTING_DETAILS_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.listing_details
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.listing_details]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_DESCRIPTION_SECTION_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.description_section
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.description_section]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_COVER_SECTION_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.cover_section
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.cover_section]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_BUTTON_TEXT_SECTION_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.button_text_section
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.button_text_section]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_COLOR_PALETTE_SECTION_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.color_palette
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.color_palette]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_DEFAULT_COLOR_PALETTE_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.default_color_palette
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.default_color_palette]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_TAB_ASSET_SECTION_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.tab_assets
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.tab_assets]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_ORDER_BUMPS_HEADING_SECTION: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.order_bumps_heading_section
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.order_bumps_heading_section]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    case CHECKOUT_PAGES_ACTION_TYPES.SET_ORDER_BUMPS_SECTION_DATA: {
      const currentFormData = state[checkout_page_reducer_keys.form_details];
      const currentListingDetailsFormData =
        state[checkout_page_reducer_keys.form_details][
          checkout_page_reducer_keys.order_bumps_details
        ];
      const newState = {
        ...state,
        [checkout_page_reducer_keys.form_details]: {
          ...currentFormData,
          [checkout_page_reducer_keys.order_bumps_details]: {
            ...currentListingDetailsFormData,
            ...payload.data,
          },
        },
      };
      return newState;
    }
    default: {
      return state;
    }
  }
}
